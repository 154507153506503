import React from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  VStack,
  theme,
  Image,
  Heading,
  HStack,
} from '@chakra-ui/react';
import SouthFloridaMap from './components/SouthFloridaMap';
import logo from './logo.png';

function App() {
  
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl">
        <Grid minH="100vh" p={3}>
          <Box bg="gray.200" p={4} shadow="md">
            <HStack justifyContent="space-between" alignItems="center">
              <HStack spacing={4}>
                <Image src={logo} alt="Logo" boxSize="50px" />
                <Heading as="h1" size="lg">Listings</Heading>
              </HStack>
              
            </HStack>
          </Box>

          <VStack spacing={8}>
            <Box width="100%" height="500px">
              <SouthFloridaMap />
            </Box>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
